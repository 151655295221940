(function ($, generic) {
  /**
   * Event listener to initalize the waitlist button when a product is sold out or coming soon.
   * Event call is coming from js/site/product_ui.js when a product is initalized or a sku is selected.
   * Passes product data to the init method of productWaitlistV1.
   */
  $(document).on('product.waitlist.init', function (event, data) {
    Drupal.behaviors.productWaitlistV1.init(data);
  });

  /**
   * Event listener to reset or hide the waitlist button when a product is available.
   * Passes product data to the reset method of productWaitlistV1.
   */
  $(document).on('product.waitlist.reset', function (event, data) {
    Drupal.behaviors.productWaitlistV1.reset(data);
  });

  Drupal.behaviors.productWaitlistV1 = {
    /**
     * Init method that handles the functionality for the notify button on a product.
     * Before initialization, does an additional check for sold out products to make sure
     * life of product basic reorder is set.
     * If everything checks out, the waitlist button is shown and the overlay event trigger is added.
     * The show method is used to display the notify button and hide the add to bag button.
     * The product SKU_BASE_ID is also added to the notify button.
     */
    init: function (data) {
      if (!data) {
        return null;
      }
      /**
       * Lets check the settings to see which INVENTORY STATUSES should be
       * used to show waitlist (different between some locales)
       */
      var waitlist_notify_inv = Drupal.settings.common.waitlist_notify_inv || '3,2,7';

      /**
       * Only show overlay for sold out / coming soon products when LIFE_OF_PRODUCT is basic reorder.
       * Now turned into a setting variable to allow for differences between locales
       */
      if (
        $.inArray(data.INVENTORY_STATUS, $.map(waitlist_notify_inv.split(','), Number)) > -1 &&
        data.LIFE_OF_PRODUCT !== this.statusIds.lifeOfProduct.basicReorder
      ) {
        return null;
      }

      var that = this;
      var $container = $(this.classes.container).filter('[data-sku-base-id=' + data.SKU_BASE_ID + ']');
      var $waitlistBtns = $(this.classes.waitlistBtn, $container);

      $waitlistBtns.each(function (index, btn) {
        // As we're updating the 1 waitlist button it could have been enabled but sku selection has changed since
        if ($(btn).hasClass(that.classes.enabled)) {
          return null;
        }
        $(btn).data('sku-base-id', data.SKU_BASE_ID);
        $(btn).on('click', function (e) {
          e.preventDefault();
          $(this).closest('#colorbox').colorbox.remove();
          $(document).trigger('product.waitlist.overlay', data);
        });

        that.show($container);
      });
    },

    /**
     * Reset method handles hidding the notify button and re-displaying add to bag button if it was hidden.
     * Primarily used for multi-sku products.
     * Uses the hide method to handle the re-displaying of add to bag and hiding notify me.
     * Won't call the hide method unless notify button is displayed.
     */
    reset: function (data) {
      if (!data) {
        return null;
      }
      var $container = $(this.classes.container).filter('[data-sku-base-id=' + data.SKU_BASE_ID + ']');
      var $waitlistBtnContainer = $(this.classes.waitlistBtn, $container);

      if (!$waitlistBtnContainer.hasClass(this.classes.enabled)) {
        return null;
      }
      this.hide($container, $waitlistBtnContainer);
    },

    // Method to handle showing and enabling of the notify button and the hidding of add to bag.
    show: function ($container) {
      var $waitlistBtnContainer = $(this.classes.waitlistBtn, $container);
      var $cartBtnContainer = $(this.classes.cartBtn, $container);

      $cartBtnContainer.addClass(this.classes.hidden);
      $waitlistBtnContainer.removeClass(this.classes.hidden);
      $waitlistBtnContainer.addClass(this.classes.active);
      $waitlistBtnContainer.addClass(this.classes.enabled);
    },

    // Method to handle hiding and disabling of the notify button and showing of add to bag.
    hide: function ($container, $waitlistBtnContainer) {
      var $cartBtnContainer = $(this.classes.cartBtn, $container);

      $cartBtnContainer.removeClass(this.classes.hidden);
      $waitlistBtnContainer.addClass(this.classes.hidden);
      $waitlistBtnContainer.removeClass(this.classes.active);
      $waitlistBtnContainer.removeClass(this.classes.enabled);
    },

    // Method to declare DOM classes used by waitlist.
    classes: {
      waitlistBtn: '.js-add-to-waitlist',
      cartBtn: '.js-add-to-cart, .js-add-to-bag',
      invStatus: '.js-inv-status-list',
      container: '.js-product',
      enabled: 'js-waitlist-enabled',
      hidden: 'hidden',
      active: 'active'
    },

    // Status ids used by the waitlist; adjust accordingly.
    statusIds: {
      lifeOfProduct: {
        basicReorder: 2
      }
    }
  };
})(jQuery);
